<template>
  <cz-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :width="800"
    max-width="90%"
    inset
    :loading="loading"
  >
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <v-card flat :disabled="loading">
        <ul>
          <v-slide-x-transition class="py-0" group tag="v-list">
            <li
              class="d-flex"
              style="gap: 1rem"
              v-for="(item, index) of conditions"
              :key="`condition_${index}`"
              :class="index < conditions.length - 1 ? 'mb-6' : ''"
            >
              <v-row>
                <v-col :cols="3">
                  <validation-provider
                    rules="required"
                    :name="$t('admin.accountWorkflowDefaults.selectField')"
                    v-slot="{ errors }"
                  >
                    <cz-autocomplete
                      v-model="item.fieldName"
                      dense
                      hide-details
                      :items="fieldItems"
                      :error-messages="errors"
                      :label="$t('admin.accountWorkflowDefaults.selectField')"
                    />
                  </validation-provider>
                </v-col>
                <v-col :cols="3">
                  <validation-provider
                    rules="required"
                    :name="$t('admin.accountWorkflowDefaults.operator')"
                    v-slot="{ errors }"
                  >
                    <cz-autocomplete
                      v-model="item.operator"
                      :label="$t('admin.accountWorkflowDefaults.operator')"
                      :items="operatorItems"
                      dense
                      hide-details
                      :error-messages="errors"
                    >
                      <template #selection="{ item }">
                        <div class="d-flex align-center" style="gap: 0.5rem">
                          <cz-icon :src="item.icon" v-if="item.icon" />
                          <span class="text-subtitle-2">{{ item.text }}</span>
                        </div>
                      </template>
                      <template #item="{ item }">
                        <div class="d-flex align-center" style="gap: 0.5rem">
                          <cz-icon :src="item.icon" v-if="item.icon" />
                          <span class="text-subtitle-2">{{ item.text }}</span>
                        </div>
                      </template>
                    </cz-autocomplete>
                  </validation-provider>
                </v-col>
                <v-col :cols="6">
                  <v-slide-y-transition>
                    <v-row v-if="item.operator" align="center">
                      <v-col :cols="item.operator === 'bt' ? 5.5 : 12">
                        <validation-provider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <cz-input
                            v-model="item.value1"
                            :error-messages="errors"
                            :label="
                              item.operator === 'bt'
                                ? $t('admin.accountWorkflowDefaults.valueFrom')
                                : $t('admin.accountWorkflowDefaults.value')
                            "
                            dense
                            type="number"
                            hide-details
                          />
                        </validation-provider>
                      </v-col>
                      <v-col :cols="1" v-if="item.operator === 'bt'">
                        <cz-icon
                          :src="mdiArrowLeft"
                          style="position: relative; left: 8px"
                        />
                      </v-col>
                      <v-col :cols="5.5" v-if="item.operator === 'bt'">
                        <validation-provider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <cz-input
                            v-model="item.value2"
                            v-if="item.operator === 'bt'"
                            :label="$t('admin.accountWorkflowDefaults.valueTo')"
                            dense
                            :error-messages="errors"
                            hide-details
                            type="number"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-slide-y-transition>
                </v-col>
              </v-row>
              <cz-button
                text
                :icon-src="mdiCloseCircleOutline"
                color="negative"
                tooltip="מחיקה"
                @click="onRemoveCondition(item)"
              />
            </li>
          </v-slide-x-transition>
        </ul>

        <cz-button
          :title="$t('admin.accountWorkflowDefaults.addCondition')"
          text
          color="info"
          :icon-src="mdiPlusCircleOutline"
          @click="onAddCondition"
          :class="conditions && conditions.length ? 'mt-6' : ''"
        />

        <div
          class="d-flex align-center justify-end full-width mt-5"
          style="gap: 1rem"
        >
          <cz-button
            v-if="!loading"
            :title="$t('common.cancel')"
            text
            color="info"
            class="px-6"
            @click="$emit('close')"
          />
          <cz-button
            color="info"
            :title="$t('common.save')"
            class="px-6"
            :disabled="invalid || loading || !formChanged"
            @click="$emit('on-save', conditions, accountWorkflow)"
          />
        </div>
      </v-card>
    </validation-observer>
  </cz-dialog>
</template>

<script>
import {
  CzDialog,
  CzButton,
  CzAutocomplete,
  CzInput,
  CzIcon
} from '@/components';
import {
  mdiPlusCircleOutline,
  mdiGreaterThan,
  mdiArrowLeftRight,
  mdiCloseCircleOutline,
  mdiEqual,
  mdiArrowLeft,
  mdiLessThanOrEqual,
  mdiGreaterThanOrEqual,
  mdiLessThan
} from '@mdi/js';
import isEqual from 'lodash.isequal';
export default {
  name: 'AccountWorkflowDefaultsDialog',
  components: {
    CzDialog,
    CzInput,
    CzAutocomplete,
    CzButton,
    CzIcon
  },
  props: {
    accountWorkflow: {
      type: Object
    },
    loading: {
      type: Boolean
    }
  },
  created() {
    if (this.accountWorkflow?.conditions) {
      this.conditions = structuredClone(this.accountWorkflow.conditions);
    } else {
      this.conditions = [];
    }
  },
  computed: {
    formChanged() {
      return !isEqual(this.accountWorkflow.conditions, this.conditions);
    },
    fieldItems() {
      return [
        {
          text: 'סכום כולל',
          value: 'totalAmount'
        }
      ];
    },
    operatorItems() {
      return [
        {
          text: this.$t('admin.accountWorkflowDefaults.operators.equal'),
          value: 'eq',
          icon: mdiEqual
        },
        {
          text: this.$t('admin.accountWorkflowDefaults.operators.greaterThan'),
          value: 'gt',
          icon: mdiGreaterThan
        },
        {
          text: this.$t(
            'admin.accountWorkflowDefaults.operators.greaterThanOrEqual'
          ),
          value: 'gte',
          icon: mdiGreaterThanOrEqual
        },

        {
          text: this.$t('admin.accountWorkflowDefaults.operators.lessThan'),
          value: 'lt',
          icon: mdiLessThan
        },
        {
          text: this.$t(
            'admin.accountWorkflowDefaults.operators.lessThanOrEqual'
          ),
          value: 'lte',
          icon: mdiLessThanOrEqual
        },
        {
          text: this.$t('admin.accountWorkflowDefaults.operators.between'),
          value: 'bt',
          icon: mdiArrowLeftRight
        }
      ];
    }
  },
  data() {
    return {
      mdiPlusCircleOutline,
      mdiCloseCircleOutline,
      mdiArrowLeft,
      conditions: []
    };
  },
  methods: {
    onAddCondition() {
      this.conditions.push({
        fieldName: '',
        operator: '',
        value1: undefined,
        value2: undefined
      });
    },
    onRemoveCondition(item) {
      this.conditions.splice(this.conditions.indexOf(item), 1);
    }
  }
};
</script>

<style lang="scss" scoped></style>
